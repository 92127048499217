<template>
  <action-add-new action-url="form-submissions/statuses" button-label="Adauga status nou">

    <template v-slot:title>
      Adauga Status nou pentru <strong>Formular de contact</strong>
    </template>

    <template v-slot:fields="{form, errors}">
      <v-text-field v-model="form.name" dense hide-details single-line outlined placeholder="Nume"/>
      <error-messages :errors="errors.name"/>
    </template>

  </action-add-new>
</template>

<script>
import ActionAddNew from '@/components/layout/ActionButtons/ActionAddNew'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'CreateFormSubmissionsStatus',
  components: {
    ErrorMessages,
    ActionAddNew
  }
}
</script>
